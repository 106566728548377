import React from 'react'
import {
  Row,
  Col,
  Gap,
  Text,
  SEO,
  Title,
  Container,
  TapsBg,
  Table,
  ArticlesSection,
  Select,
  Tooltip,
} from '../components'
import priceList from '../lib/priceList'

import { Sort } from '../components/icons'

import priceIconSrc from '../data/images/icons/price.svg'

const HeaderText = props => (
  <Text fontSize="16px" lineHeight="24px" fontWeight="700" {...props} />
)
const ColText = props => (
  <Text fontSize="16px" lineHeight="16px" fontWeight="400" {...props} />
)

const SortSign = ({ isActive, isUp }) => (
  <>
    <Gap gap="16px" />
    <Col>
      <Sort isActive={isUp && isActive} />
      <Gap gap="4px" />
      <Sort isActive={!isUp && isActive} down />
    </Col>
  </>
)

const doublePriceTooltipContent = `Obyvatelé tohoto města platí dvousloužkovou cenu vody, která je
složena z vodného, stočného a navíc z paušálního poplatku za
vodoměr.

Cena vody v naší databázi byla přepočtena na jednosložkovou,
aby ceny byly porovnatelné.
`

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sortProp: '',
      sortUp: false,
      isNumberSort: false,
      selectedRegionIndex: 0,
      selectedRegionLabel: '',
    }
  }

  setSort = (newSortProp, isNumberSort = false) => {
    this.setState(({ sortUp, sortProp }) => ({
      sortProp: newSortProp,
      sortUp: newSortProp === sortProp ? !sortUp : sortUp,
      isNumberSort,
    }))
  }

  setSelectedRegion = (index, { label }) => {
    this.setState({ selectedRegionIndex: index, selectedRegionLabel: label })
  }

  render() {
    const {
      location: { pathname },
    } = this.props
    const {
      sortProp,
      sortUp,
      isNumberSort,
      selectedRegionIndex,
      selectedRegionLabel,
    } = this.state
    let data = priceList
    if (selectedRegionIndex) {
      data = data.filter(({ region }) =>
        selectedRegionLabel.toLocaleLowerCase().includes(region.toLowerCase()))
    }

    data = data.sort((rowA, rowB) => {
      let a = rowA[sortProp]
      let b = rowB[sortProp]
      let result
      if (isNumberSort) {
        a = a.replace(',', '.')
        b = b.replace(',', '.')
        result = b - a
      } else {
        result = a && b && a.localeCompare(b)
      }
      if (sortUp) result = -result
      return result
    })

    return (
      <>
        <SEO pathname={pathname} title="Ceník" />
        <Gap gap="135px" mobileGap="80px" />

        <Gap gap="250px" mobileGap="150px" bp="mobile" />
        <TapsBg
          top="240px"
          topMobile="150px"
          left="unset"
          xCount={7}
          yCount={1}
          transparent
          bp="mobile"
        />

        <Container>
          <Col
            width="100%"
            justifyContent="center"
            mobileAlignItems="center"
            alignItems="flex-start"
            maxWidth="870px"
            alignSelf="center"
          >
            <Title.Article alignSelf="unset">
              Srovnání cen vody ve 100 největších městech ČR
            </Title.Article>
            <Gap.Title />
            <Select.Regions
              withCountry
              value={selectedRegionIndex}
              onChange={this.setSelectedRegion}
            />
            <Gap gap="40px" />
            <Row>
              <Gap gap="26px" />
              <img src={priceIconSrc} alt="dvousložková cena" />
              <Gap gap="10px" />
              <Text fontSize="16px" lineHeight="24px" maxWidth="790px">
                Obyvatelé tohoto města platí dvousloužkovou cenu vody, která je
                složena z vodného, stočného a navíc z paušálního poplatku za
                vodoměr.{' '}
                <strong>
                  Cena vody v naší databázi byla přepočtena na jednosložkovou,
                  aby ceny byly porovnatelné.
                </strong>
              </Text>
            </Row>
            <Gap gap="24px" />
            <Text fontSize="16px" lineHeight="24px">* Uvedené ceny jsou včetně DPH</Text>
            <Gap gap="24px" />

            <Table>
              <thead>
                <Table.HeaderRow>
                  <Table.Header onClick={() => this.setSort('city')}>
                    <Row alignItems="center">
                      <HeaderText>Město</HeaderText>
                      <SortSign isActive={sortProp === 'city'} isUp={sortUp} />
                    </Row>
                  </Table.Header>
                  <Table.Header
                    onClick={() => this.setSort('invoicedPrice', true)}
                  >
                    <Row alignItems="center">
                      <HeaderText>Vodné</HeaderText>
                      <SortSign
                        isActive={sortProp === 'invoicedPrice'}
                        isUp={sortUp}
                      />
                    </Row>
                  </Table.Header>
                  <Table.Header
                    onClick={() => this.setSort('dischargedPrice', true)}
                  >
                    <Row alignItems="center">
                      <HeaderText>Stočné</HeaderText>
                      <SortSign
                        isActive={sortProp === 'dischargedPrice'}
                        isUp={sortUp}
                      />
                    </Row>
                  </Table.Header>
                  <Table.Header onClick={() => this.setSort('sum', true)}>
                    <Row alignItems="center">
                      <HeaderText>Celkem</HeaderText>
                      <SortSign isActive={sortProp === 'sum'} isUp={sortUp} />
                    </Row>
                  </Table.Header>
                </Table.HeaderRow>
              </thead>
              <tbody>
                <tr style={{ height: '12px' }} />
                {data.map(row => (
                  <Table.Row key={row.id}>
                    <Table.Col>
                      <Col maxWidth="300px">
                        <ColText fontWeight="700">{row.city}</ColText>
                        <ColText fontSize="12px" lineHeight="16px">
                          {row.distributor}
                        </ColText>
                      </Col>
                    </Table.Col>
                    <Table.Col>
                      <ColText>{row.invoicedPrice}&nbsp;Kč</ColText>
                    </Table.Col>
                    <Table.Col>
                      <ColText>{row.dischargedPrice}&nbsp;Kč</ColText>
                    </Table.Col>
                    <Table.Col>
                      <Row alignItems="center">
                        <ColText fontWeight="700">
                          {row.sum}
                          &nbsp;Kč
                        </ColText>
                        <Gap gap="20px" />
                        <ColText>
                          {row.isDoublePrice && (
                            <Tooltip content={doublePriceTooltipContent} noUnderline forcedOverflow>
                              <img src={priceIconSrc} alt="dvousložková cena" style={{ padding: '5px' }} />
                            </Tooltip>
                          )}
                        </ColText>
                      </Row>
                    </Table.Col>
                  </Table.Row>
                ))}
                <tr style={{ height: '12px' }} />
              </tbody>
            </Table>
          </Col>
          <Gap gap="125px" />
          <ArticlesSection page="PRICE" pathname={pathname} />
          <Gap gap="220px" />
        </Container>
      </>
    )
  }
}

export default Page
